import logo from '../assets/images/dogs.jpg';
import '../styles/App.css';

function homepage() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="dog-photo" alt="logo" />
      </header>
    </div>
  );
}

export default homepage;