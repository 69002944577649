import React from 'react';
import './styles/App.css';
import Homepage from './components/homepage';  // Importing the Homepage component

function App() {
  return (
    <div className="App">
      <Homepage />  {/* Rendering the Homepage component */}
    </div>
  );
}

export default App;